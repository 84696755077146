<form class="main-form" [formGroup]="formForgot" (ngSubmit)="resetPassword()">
  <mat-card class="content-form" *ngIf="!passwordReset; else success;">
    <img mat-card-image class="logo" src="/assets/lilylearn_fulllogo.svg" />
    <mat-card-content>
      <mat-form-field class="example-full-width">
        <mat-label>{{ 'password' | translate }}</mat-label>
        <input matInput formControlName="password" [type]="getType('new')" placeholder="" [errorStateMatcher]="matcher">
        <mat-icon class="pointer" *ngIf="isActiveToggleTextPasswordN" matSuffix (click)="toggleTextPassword('new')">visibility</mat-icon>
        <mat-icon class="pointer" *ngIf="!isActiveToggleTextPasswordN" matSuffix (click)="toggleTextPassword('new')">visibility_off</mat-icon>
      </mat-form-field>
      <mat-list>
        <div *ngIf="formForgot.controls['password'].hasError('required') || formForgot.controls['password'].hasError('minlength'); then errorLength; else successLength">
        </div>
        <ng-template #errorLength>
          <mat-list-item>
            <mat-icon class="error" mat-list-icon>cancel</mat-icon>
            <div class="error" mat-line>{{ 'password_length' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <ng-template #successLength>
          <mat-list-item>
            <mat-icon class="success" mat-list-icon>check_circle</mat-icon>
            <div class="success" mat-line>{{ 'password_length' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <div *ngIf="formForgot.controls['password'].hasError('required') || formForgot.controls['password'].hasError('hasNumber'); then errorNumber; else successNumber">
        </div>
        <ng-template #errorNumber>
          <mat-list-item>
            <mat-icon class="error" mat-list-icon>cancel</mat-icon>
            <div class="error" mat-line>{{ 'password_number' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <ng-template #successNumber>
          <mat-list-item>
            <mat-icon class="success" mat-list-icon>check_circle</mat-icon>
            <div class="success" mat-line>{{ 'password_number' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <div *ngIf="formForgot.controls['password'].hasError('required') || formForgot.controls['password'].hasError('hasCapitalCase'); then errorCapital; else successCapital">
        </div>
        <ng-template #errorCapital>
          <mat-list-item>
            <mat-icon class="error" mat-list-icon>cancel</mat-icon>
            <div class="error" mat-line>{{ 'password_caps' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <ng-template #successCapital>
          <mat-list-item>
            <mat-icon class="success" mat-list-icon>check_circle</mat-icon>
            <div class="success" mat-line>{{ 'password_caps' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <div *ngIf="formForgot.controls['password'].hasError('required') || formForgot.controls['password'].hasError('hasSmallCase'); then errorSmall; else successSmall">
        </div>
        <ng-template #errorSmall>
          <mat-list-item>
            <mat-icon class="error" mat-list-icon>cancel</mat-icon>
            <div class="error" mat-line>{{ 'password_min' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <ng-template #successSmall>
          <mat-list-item>
            <mat-icon class="success" mat-list-icon>check_circle</mat-icon>
            <div class="success" mat-line>{{ 'password_min' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <div *ngIf="formForgot.controls['password'].hasError('required') || formForgot.controls['password'].hasError('hasSpecialCharacters'); then error; else success">
        </div>
        <ng-template #error>
          <mat-list-item>
            <mat-icon class="error" mat-list-icon>cancel</mat-icon>
            <div class="error" mat-line>{{ 'password_spec' | translate }}</div>
          </mat-list-item>
        </ng-template>
        <ng-template #success>
          <mat-list-item>
            <mat-icon class="success" mat-list-icon>check_circle</mat-icon>
            <div class="success" mat-line>{{ 'password_spec' | translate }}</div>
          </mat-list-item>
        </ng-template>
      </mat-list>
      <mat-form-field class="example-full-width">
        <mat-label>{{ 'confirm_password' | translate }}</mat-label>
        <input matInput formControlName="password_confirm" [type]="getType('confirm')" placeholder="" [errorStateMatcher]="matcher">
        <mat-icon class="pointer" *ngIf="isActiveToggleTextPasswordC" matSuffix (click)="toggleTextPassword('confirm')">visibility</mat-icon>
        <mat-icon class="pointer" *ngIf="!isActiveToggleTextPasswordC" matSuffix (click)="toggleTextPassword('confirm')">visibility_off</mat-icon>
        <mat-error *ngIf="formForgot.controls['password_confirm'].hasError('NoPassswordMatch')">
          {{ 'password_match' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button class="register" [disabled]="!formForgot.valid" mat-raised-button>{{ 'save' | translate }}</button>
    </mat-card-actions>
  </mat-card>
  <ng-template #success>
    <mat-card class="success-card">
      <mat-card-content>
        <mat-icon class="icon-success">task_alt</mat-icon>
      </mat-card-content>
      <mat-card-content>
        {{ 'success_password' | translate }}
      </mat-card-content>
    </mat-card>
  </ng-template>
</form>