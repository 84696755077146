import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { CustomValidators } from './class/custom-validators';
import { DeeplinkService } from './services/deeplink.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public formForgot: FormGroup;
  public isActiveToggleTextPasswordN: Boolean = true;
  public isActiveToggleTextPasswordC: Boolean = true;
  public passwordReset: Boolean = false;
  public urlParams: URLSearchParams;

  public matcher = new MyErrorStateMatcher();

  constructor(
    private deeplinkService : DeeplinkService,
    private formBuilder: FormBuilder,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar
  ) {
    this.formForgot = this.formBuilder.group({
      password: [null, Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(12)
      ])],
      password_confirm: [null, Validators.required],
    },
    {
      validators: CustomValidators.passwordMatchValidator,
      updateOn: 'change'
    });
  }
  
  ngOnInit() {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
    this.deeplinkService.deeplink(this.urlParams)
  }

  public toggleTextPassword(value): void{
    if (value === 'new') {
      this.isActiveToggleTextPasswordN = (this.isActiveToggleTextPasswordN==true)?false:true;
    } else this.isActiveToggleTextPasswordC = (this.isActiveToggleTextPasswordC==true)?false:true;
  }

  public getType(value) {
    if (value === 'new') {
      return this.isActiveToggleTextPasswordN ? 'password' : 'text';
    } else return this.isActiveToggleTextPasswordC ? 'password' : 'text';
  }

  resetPassword() {
    const code = this.urlParams.get('oobCode');
    const password = this.formForgot.controls['password'].value;
    
    this.afAuth.confirmPasswordReset(code, password)
    .catch(error => {
      this.snackBar.open(error, null, {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    })
    .then(() => {
      this.passwordReset = true;
    });
  }

}
