import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class DeeplinkService {

  constructor() {}
  
  deeplink(params: URLSearchParams) {
    console.log(params)
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
    if (isIphone == true) {
      window.location.href = `com.lilylearn.lilylearn://forgot?code=${params.get('oobCode')}`;
    } else if (isAndroid== true) {
      window.location.replace(`com.lilylearn.lilylearn://forgot?code=${params.get('oobCode')}`);
    }
  }

}
