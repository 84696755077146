import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    if (control) {
      const password: string = control.get('password').value; // get password from our password form control
      const confirmPassword: string = control.get('password_confirm').value; // get password from our password_confirm form control
      // compare is the password math
      if (password !== confirmPassword) {
        // if they don't match, set an error in our password_confirm form control
        control.get('password_confirm').setErrors({ NoPassswordMatch: true });
        return ({ NoPassswordMatch: true })
      }
    }
    return null;
  }
}